/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).ready(function () {

          var $navTrigger = $('.navbar-toggle');
          function openNav() {
            $( "html" ).addClass( 'menuopen' );
            $( "html" ).removeClass( 'menuclosed' );
          }
          function closeNav() {
            $( "html" ).removeClass( 'menuopen' );
            $( "html" ).addClass( 'menuclosed' );
          }
          function subNavigation() {
            $(".dropdown .caret").on('click', function(e){
              if ($(this).hasClass('open')) {
                $(this).removeClass( 'open' ).addClass( 'closed' );
                $(this).parent().removeClass( 'mopen' ).addClass( 'notmopen' );
                $(this).next('.dropdown-menu').removeClass( 'active' ).addClass( 'notactive' );
              } else {
                $(this).removeClass( 'closed' ).addClass( 'open' );
                $(this).parent().removeClass( 'notmopen' ).addClass( 'mopen' );
                $(this).next('.dropdown-menu').removeClass( 'notactive' ).addClass( 'active' );
              }
            });
          }
          function globalNav() {
            $($navTrigger).on('click', function(e) {
              $(this).toggleClass('is-active');
                event.preventDefault();
                if ($('.navbar-primary').hasClass('show')) {
                  closeNav();
                } else {
                  openNav();
                }
              });
            }
          $(window).scroll(function() {
            if ($(document).scrollTop() > 100) {
              $('header.fixed-top').addClass('shrink');
            } else {
              $('header.fixed-top').removeClass('shrink');
            }
          });
          globalNav();
          subNavigation();

        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(document).ready(function () {

          // Select all links with hashes
          $('a[href*="#"]')
          // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function(event) {
            // On-page links
            if ( location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname ) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top - 100
                }, 1000, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                });
              }
            }
          });

        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(document).ready(function () {

          $('#homeModal').on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget); // Button that triggered the modal
            var title = button.data('title'); // Extract info from data-* attributes
            var videoSRC = button.data('video'); // Extract info from data-* attributes
            var videoSRCauto = videoSRC+"?autoplay=1&rel=0" ;
            var modal = $(this);
            modal.find('.modal-title').text(title);
            modal.find('iframe').attr('src', videoSRCauto);
          });

          $("#homeModal").on('hidden.bs.modal', function (e) {
              $("#homeModal iframe").removeAttr('src');
          });

        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Dashboard page
    'dashboard': {
      init: function() {
        // JavaScript to be fired on the dashboard page
        $(document).ready(function () {
			    var $link;
			    $(".ssa-upcoming-appointments-appointment").each(function( index, element ) {
				    $link = $( element ).find("a");
				    $link.removeAttr("target");
				    $link.attr("data-appointment", $link.attr("href") );
				    $link.attr("href","#").attr("data-toggle","modal").attr("data-target","#appointmentModal").addClass("btn btn-primary");
				  
			    });
			  
			    $('#appointmentModal').on('show.bs.modal', function (event) {
				  var button = $(event.relatedTarget); // Button that triggered the modal
				  var appointmentSRC = button.data('appointment'); // Extract info from data-* attributes
				  var modal = $(this);
				  modal.find('iframe').attr('src', appointmentSRC);
				  $("body").addClass("modal-active");
			    });
	 
			    $("#appointmentModal").on('hidden.bs.modal', function (e) {
				    $("#appointmentModal iframe").removeAttr('src');
			    });
			  
			    $("#refreshMe").click(function() {
				    location.reload();
			    });

        });
      }
    },
    // Pre-Appointment Lab Tests page
    'lab_tests': {
      init: function() {
        // JavaScript to be fired on the dashboard page
        $(document).ready(function () {
			    var $link;
			    $(".ssa-upcoming-appointments-appointment").each(function( index, element ) {
				    $link = $( element ).find("a");
				    $link.removeAttr("target");
				    $link.attr("data-appointment", $link.attr("href") );
				    $link.attr("href","#").attr("data-toggle","modal").attr("data-target","#appointmentModal").addClass("btn btn-primary");
				  
			    });
			  
			    $('#appointmentModal').on('show.bs.modal', function (event) {
				  var button = $(event.relatedTarget); // Button that triggered the modal
				  var appointmentSRC = button.data('appointment'); // Extract info from data-* attributes
				  var modal = $(this);
				  modal.find('iframe').attr('src', appointmentSRC);
				  $("body").addClass("modal-active");
			    });
	 
			    $("#appointmentModal").on('hidden.bs.modal', function (e) {
				    $("#appointmentModal iframe").removeAttr('src');
			    });
			  
			    $("#refreshMe").click(function() {
				    location.reload();
			    });

        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
